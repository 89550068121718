<template>
  <div class="space-y-12">
    <div class="grid grid-cols-12 px-4 sm:px-32 pt-24">
      <div class="col-span-12 sm:col-span-6">
        <p class="text-14 text-blue-light font-bold observeFadeIn">PHOTOS</p>
        <h2
          class="lg:text-xl xl:text-42 font-extrabold xl:leading-56 observeFadeInSlow"
        >
          See our latest photos from restaurant and hotel
        </h2>
      </div>
    </div>
    <div class="items flex overflow-x-hidden relative observeFadeInSlow">
      <div
        class="left-arrow flex absolute justify-center self-center ml-4 sm:ml-20 z-10"
      >
        <div
          @click="slideLeft"
          style="transition: background 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
          class="p-6 bg-blue-light cursor-pointer"
          :class="position === 0 ? 'bg-opacity-50' : ''"
        >
          <LeftArrowIcon
            :stroke="position === 0 ? 'stroke: white' : 'stroke: black'"
          />
        </div>
      </div>
      <div
        class="right-arrow absolute flex justify-center self-center mr-4 sm:mr-20 z-10"
        style="right: 0px"
      >
        <div
          class="p-6 bg-blue-light cursor-pointer"
          @click="slideRight"
          style="transition: background 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
          :class="position === -1280 ? 'bg-opacity-50' : ''"
        >
          <RightArrowIcon
            :stroke="position === -1280 ? 'stroke: white' : 'stroke: black'"
          />
        </div>
      </div>
      <div
        :style="`transform:translateX(${position}px)`"
        style="transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
        class="items-container flex"
      >
        <div>
          <div>
            <img
              class="max-w-320 sm:max-w-400"
              src="@/assets/img/photos_a.png"
            />
          </div>
        </div>
        <div>
          <div>
            <img
              class="max-w-320 sm:max-w-400"
              src="@/assets/img/photos_b.png"
            />
          </div>
        </div>
        <div>
          <div>
            <img
              class="max-w-320 sm:max-w-400"
              src="@/assets/img/photos_c.png"
            />
          </div>
        </div>
        <div>
          <div>
            <img
              class="max-w-320 sm:max-w-400"
              src="@/assets/img/photos_d.png"
            />
          </div>
        </div>
        <div>
          <div>
            <img
              class="max-w-320 sm:max-w-400"
              src="@/assets/img/photos_a.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftArrowIcon from "@/assets/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  data() {
    return {
      position: 0,
    };
  },
  components: {
    LeftArrowIcon,
    RightArrowIcon,
  },
  methods: {
    slideRight() {
      if (this.position > -1280) {
        this.position -= 320;
      }
    },
    slideLeft() {
      if (this.position < 0) {
        this.position += 320;
      }
    },
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInDelay", "animated fadeIn delay-1s");
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
  },
};
</script>