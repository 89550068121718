<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16.828"
    viewBox="0 0 16 16.828"
  >
    <g transform="translate(-4 -3.586)">
      <line :style="stroke" class="a" x2="14" transform="translate(5 12)" />
      <path :style="stroke" class="a" d="M12,5l7,7-7,7" />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    stroke: {
      default: "stroke: white",
    },
  },
};
</script>
<style>
.a {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
</style>