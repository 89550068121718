<template>
  <div>
    <BreadCrumbs />
    <RoomsAboutUs />
    <RoomsBooking />
    <Photos />
    <Footer />
  </div>
</template>
<script>
import BreadCrumbs from "@/layouts/BreadCrumbs.vue";
import RoomsAboutUs from "@/layouts/RoomsAboutUs.vue";
import RoomsBooking from "@/layouts/RoomsBooking.vue";
import Photos from "@/layouts/Photos.vue";
import Footer from "@/layouts/Footer.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    BreadCrumbs,
    RoomsAboutUs,
    RoomsBooking,
    Photos,
    Footer,
  },
};
</script>