<template>
  <div class="px-4 sm:px-32 pt-24 space-y-12">
    <div class="grid sm:grid-cols-12">
      <div class="col-span-6">
        <p class="text-14 text-blue-light font-bold observeFadeIn">
          RESTAURANT
        </p>
        <h2
          class="lg:text-xl xl:text-42 font-extrabold xl:leading-56 observeFadeInSlow"
        >
          Explore our menu and eat what you want
        </h2>
      </div>
    </div>
    <div class="menu grid grid-cols-12 gap-4">
      <div
        class="col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeIn"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/food.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">FOOD</p>
          <p class="font-semibold text-sm xl:text-18">Fresh fish and onions</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute"
          style="bottom: 0px; right: 0px"
        >
          <div class="p-6 bg-blue-light">
            <RightArrowIcon />
          </div>
        </div>
      </div>
      <div
        class="col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/dessert.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">DESSERT</p>
          <p class="font-semibold text-sm xl:text-18">Chocolate cupcakes</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute"
          style="bottom: 0px; right: 0px"
        >
          <div class="p-6 bg-blue-light">
            <RightArrowIcon />
          </div>
        </div>
      </div>
      <div
        class="col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay2"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/dessert.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">DESSERT</p>
          <p class="font-semibold text-sm xl:text-18">Chocolate cupcakes</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute"
          style="bottom: 0px; right: 0px"
        >
          <div class="p-6 bg-blue-light">
            <RightArrowIcon />
          </div>
        </div>
      </div>
      <div
        class="col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay3"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/food.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">FOOD</p>
          <p class="font-semibold text-sm xl:text-18">Fresh fish and onions</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute"
          style="bottom: 0px; right: 0px"
        >
          <div class="p-6 bg-blue-light">
            <RightArrowIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    RightArrowIcon,
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInDelay", "animated fadeIn delay-1s");
    this.observeInteraction("observeFadeInDelay2", "animated fadeIn delay-2s");
    this.observeInteraction("observeFadeInDelay3", "animated fadeIn delay-3s");
    this.observeInteraction("observeFadeInDelay4", "animated fadeIn delay-4s");
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
    this.observeInteraction("observeFadeInSlower", "animated fadeIn slower");
  },
};
</script>