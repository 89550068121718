<template>
  <div
    class="pt-430 lg:pt-40 pb-20 px-4 sm:px-32 grid grid-cols-12 gap-y-4 lg:gap-y-0"
  >
    <div
      class="col-span-12 lg:col-span-6 space-y-4 lg:w-5/6 xl:w-4/6 grid content-center"
    >
      <div>
        <p class="text-14 text-blue-light font-bold observeFadeIn">ABOUT US</p>
        <h2
          class="text-xl xl:text-42 font-extrabold xl:leading-56 observeFadeInSlow"
        >
          Trust and quality are our atuus
        </h2>
      </div>
      <p
        class="text-xs xl:text-16 font-medium xl:leading-25 observeFadeInSlower"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <p class="text-xs xl:text-14 xl:leading-21 observeFadeInSlower">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
      <div>
        <button
          class="bg-blue-light text-white px-14 py-4 flex items-center space-x-2 observeFadeInSlow3"
        >
          <span class="text-14">read more</span><RightArrowIcon />
        </button>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-6 space-y-4">
      <div class="grid grid-cols-12 gap-x-4">
        <div class="col-span-6 observeFadeIn">
          <img src="@/assets/img/about_a.png" />
        </div>
        <div class="col-span-6 observeFadeInLeftDelay">
          <img src="@/assets/img/about_b.png" />
        </div>
      </div>
      <div class="grid grid-cols-12">
        <div class="col-span-12 observeFadeInDownDelay">
          <img src="@/assets/img/about_c.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    RightArrowIcon,
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInUp", "animated fadeInUp");
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
    this.observeInteraction("observeFadeInSlower", "animated fadeIn slower");
    this.observeInteraction("observeFadeInSlow3", "animated fadeIn slow3");
    this.observeInteraction(
      "observeFadeInLeftDelay",
      "animated fadeInLeft delay-1s"
    );
    this.observeInteraction(
      "observeFadeInDownDelay",
      "animated fadeInDown delay-2s"
    );
  },
};
</script>