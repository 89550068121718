<template>
  <div class="grid grid-cols-12">
    <div
      class="col-span-12 lg:col-span-7 px-4 sm:px-32 bg-gray-bg grid content-center space-y-4 relative pb-4 lg:pb-0"
    >
      <div class="pt-4 lg:pt-0">
        <p class="text-14 text-blue-light font-bold observeFadeIn">
          TESTIMONIALS
        </p>
        <h2
          class="lg:text-xl xl:text-42 font-extrabold xl:leading-56 observeFadeInSlow"
        >
          We trust our customers and they trust us too
        </h2>
      </div>
      <Testimonies class="observeFadeInSlower" />
      <div
        class="arrows flex justify-end self-center absolute"
        style="bottom: 0px; right: 0px"
      >
        <div class="p-6 bg-blue-light">
          <LeftArrowIcon />
        </div>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-5 relative observeFadeIn">
      <img src="@/assets/img/testimonial.png" />
      <div
        class="arrows flex justify-end self-center absolute"
        style="bottom: 0px; left: 0px"
      >
        <div class="p-6 bg-white">
          <RightArrowIcon stroke="stroke: black" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftArrowIcon from "@/assets/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import Testimonies from "@/components/Testimonies.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    Testimonies,
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
    this.observeInteraction("observeFadeInSlower", "animated fadeIn slower");
  },
};
</script>