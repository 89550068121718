<template>
  <div class="px-4 sm:px-32 pt-24 pb-20 space-y-12">
    <div class="grid sm:grid-cols-12">
      <div class="col-span-6">
        <p class="text-14 text-blue-light font-bold observeFadeIn">
          RESTAURANT
        </p>
        <h2
          class="lg:text-xl xl:text-42 font-extrabold xl:leading-56 observeFadeInSlow"
        >
          Explore our menu and eat what you want
        </h2>
      </div>
    </div>
    <div class="menu grid grid-cols-12 gap-4">
      <div
        class="big-room col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeIn"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/big_room.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">BIG</p>
          <p class="font-semibold text-sm xl:text-18">Spacious Room</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            The most spacious room you will ever see in our hotel. Equipped with
            span and warm beds
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute h-full"
          style="right: 0px"
        >
          <div class="p-6 bg-blue-light h-full grid items-center">
            <RightArrowIcon stroke="stroke: black" />
          </div>
        </div>
      </div>
      <div
        class="small-room col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/small_room.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">SMALL</p>
          <p class="font-semibold text-sm xl:text-18">Small Cheap Room</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Smallest and the most economic room in our hotel. Save an extra buck
            for your holyday
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute h-full"
          style="right: 0px"
        >
          <div class="p-6 bg-blue-light h-full grid items-center">
            <RightArrowIcon stroke="stroke: black" />
          </div>
        </div>
      </div>
      <div
        class="premium-room col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay2"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/premium_room.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">PREMIUM</p>
          <p class="font-semibold text-sm xl:text-18">Big Room</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Biggest room in our hotel fully equipped with Wi-fi and hot coffee
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute h-full"
          style="right: 0px"
        >
          <div class="p-6 bg-blue-light h-full grid items-center">
            <RightArrowIcon stroke="stroke: black" />
          </div>
        </div>
      </div>
      <div
        class="expensive-room col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay3"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/expensive_room.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">EXPENSIVE</p>
          <p class="font-semibold text-sm xl:text-18">Expensive Luxury Room</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            The most expensive room available for milionars and very rich people
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute h-full"
          style="right: 0px"
        >
          <div class="p-6 bg-blue-light h-full grid items-center">
            <RightArrowIcon stroke="stroke: black" />
          </div>
        </div>
      </div>
      <div
        class="big-room col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay4"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/big_room.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">BIG</p>
          <p class="font-semibold text-sm xl:text-18">Spacious Room</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            The most spacious room you will ever see in our hotel. Equipped with
            span and warm beds
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute h-full"
          style="right: 0px"
        >
          <div class="p-6 bg-blue-light h-full grid items-center">
            <RightArrowIcon stroke="stroke: black" />
          </div>
        </div>
      </div>
      <div
        class="small-room col-span-12 lg:col-span-6 grid grid-cols-12 bg-gray-bg relative observeFadeInDelay5"
      >
        <div class="col-span-5 sm:col-span-4 lg:col-span-6 xl:col-span-4">
          <img src="@/assets/img/small_room.png" />
        </div>
        <div
          class="col-span-7 sm:col-span-8 lg:col-span-6 xl:col-span-8 sm:w-4/6 px-4 grid content-center"
        >
          <p class="font-bold text-14 text-blue-light">SMALL</p>
          <p class="font-semibold text-sm xl:text-18">Small Cheap Room</p>
          <p class="font-medium text-10 xl:text-14 text-gray-dark pt-2">
            Smallest and the most economic room in our hotel. Save an extra buck
            for your holyday
          </p>
        </div>
        <div
          class="hidden sm:block arrows flex justify-end self-center absolute h-full"
          style="right: 0px"
        >
          <div class="p-6 bg-blue-light h-full grid items-center">
            <RightArrowIcon stroke="stroke: black" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    RightArrowIcon,
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInDelay", "animated fadeIn delay-1s");
    this.observeInteraction("observeFadeInDelay2", "animated fadeIn delay-2s");
    this.observeInteraction("observeFadeInDelay3", "animated fadeIn delay-3s");
    this.observeInteraction("observeFadeInDelay4", "animated fadeIn delay-4s");
    this.observeInteraction("observeFadeInDelay5", "animated fadeIn delay-5s");
    this.observeInteraction("observeFadeInDelay6", "animated fadeIn delay-6s");
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
    this.observeInteraction("observeFadeInSlower", "animated fadeIn slower");
  },
};
</script>