<template>
  <div class="about_2 py-20 space-y-10 bg-opacity-25">
    <div class="px-4 lg:px-20 xl:px-32 grid xl:grid-cols-12">
      <div class="col-span-6 xl:w-4/6">
        <p class="text-14 text-blue-light font-bold observeFadeIn">ABOUT US</p>
        <h2
          class="lg:text-xl xl:text-42 font-extrabold xl:leading-56 text-white observeFadeInSlow"
        >
          We bring you the finest details
        </h2>
      </div>
    </div>
    <div class="grid grid-cols-12 px-4 lg:px-0 observeFadeIn">
      <!-- arrows for mobile -->
      <div class="lg:hidden col-span-12 grid grid-cols-12 gap-x-4 pb-4">
        <div class="left-arrow col-span-6 self-center">
          <div class="arrows flex justify-end self-center">
            <div
              @click="slideLeft"
              style="transition: background 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
              class="p-6 bg-white cursor-pointer"
              :class="position === 0 ? 'bg-opacity-50' : ''"
            >
              <LeftArrowIcon
                :stroke="position === 0 ? 'stroke: black' : 'stroke: blue'"
              />
            </div>
          </div>
        </div>
        <div class="right-arrow col-span-6 self-center">
          <div class="arrows flex justify-start self-center">
            <div
              class="p-6 bg-white cursor-pointer"
              @click="slideRight"
              style="transition: background 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
              :class="position === -1160 ? 'bg-opacity-50' : ''"
            >
              <RightArrowIcon
                :stroke="position === -1160 ? 'stroke: black' : 'stroke: blue'"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- arrows for desktop -->
      <div class="left-arrow hidden lg:block col-span-1 self-center">
        <div class="arrows flex justify-center self-center">
          <div
            @click="slideLeft"
            style="transition: background 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
            class="p-6 bg-white cursor-pointer"
            :class="position === 0 ? 'bg-opacity-50' : ''"
          >
            <LeftArrowIcon
              :stroke="position === 0 ? 'stroke: black' : 'stroke: blue'"
            />
          </div>
        </div>
      </div>
      <div class="items col-span-12 lg:col-span-10 overflow-x-hidden">
        <div
          :style="`transform:translateX(${position}px)`"
          style="transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
          class="items-container flex gap-x-4"
        >
          <div class="col-span-3">
            <Feature
              title="Free Wifi to stay connected"
              body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt"
            />
          </div>
          <div class="col-span-3">
            <Feature
              title="4K Television and Netflix included"
              body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt"
            />
          </div>
          <div class="col-span-3">
            <Feature
              title="Span zone where you can chill"
              body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt"
            />
          </div>
          <div class="col-span-3">
            <Feature
              title="Spacious rooms for awesome parties"
              body="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt"
            />
          </div>
        </div>
      </div>
      <div class="right-arrow hidden lg:block col-span-1 self-center">
        <div class="arrows flex justify-center self-center">
          <div
            class="p-6 bg-white cursor-pointer"
            @click="slideRight"
            style="transition: background 0.6s cubic-bezier(0.16, 1, 0.3, 1)"
            :class="position === -1160 ? 'bg-opacity-50' : ''"
          >
            <RightArrowIcon
              :stroke="position === -1160 ? 'stroke: black' : 'stroke: blue'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftArrowIcon from "@/assets/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import Feature from "@/components/Feature.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  data() {
    return {
      position: 0,
    };
  },
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    Feature,
  },
  methods: {
    slideRight() {
      if (this.position > -1160) {
        this.position -= 290;
      }
    },
    slideLeft() {
      if (this.position < 0) {
        this.position += 290;
      }
    },
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
  },
};
</script>
<style scoped>
.about_2 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../assets/img/about_2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>