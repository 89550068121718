<template>
  <div class="p-6 space-y-4 bg-white min-h-261 min-w-290 sm:min-w-385">
    <div class="flex">
      <div class="p-4 bg-blue-light rounded-full mx-auto">
        <HotelIcon />
      </div>
    </div>
    <div class="text-center space-y-4">
      <p class="text-18 font-semibold lg:w-5/6 mx-auto">
        {{ title }}
      </p>
      <p class="text-gray-dark text-14 font-medium">
        {{ body }}
      </p>
    </div>
  </div>
</template>
<script>
import HotelIcon from "@/assets/icons/HotelIcon.vue";
export default {
  components: {
    HotelIcon,
  },
  props: {
    title: {
      type: String,
    },
    body: {
      type: String,
    },
  },
};
</script>