<template>
  <div>
    <div class="flex gap-x-2">
      <div
        v-for="n in noOfIndicators"
        :key="n"
        class="w-2 h-2 rounded-md cursor-pointer transition-all duration-500 linear rounded-full"
        :class="n === active ? 'bg-blue-light' : 'bg-gray-circle'"
        @click="changeActive(n)"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      noOfIndicators: 4,
    };
  },
  props: {
    active: {
      default: 1,
    },
  },
  methods: {
    changeActive(indicator) {
      this.$emit("changetestimony", indicator);
    },
  },
};
</script>