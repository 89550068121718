<template>
  <div class="hero text-white">
    <div
      class="navbar items-center grid grid-cols-12 px-4 sm:px-32 py-6 observeFadeIn"
    >
      <div class="col-span-4 sm:col-span-2 logo">
        <p class="font-semibold text-14">Zetta Hotels</p>
      </div>
      <div
        class="col-span-8 sm:col-span-10 nav-items grid lg:hidden justify-end self-center relative"
      >
        <div>
          <MenuIcon
            :active="active"
            @toogleMenu="toogleMenu($event)"
          ></MenuIcon>
        </div>
        <div
          v-show="active"
          class="absolute bg-black grid justify-end p-4"
          style="top: 25px; right: 0px"
        >
          <a href="/" class="font-medium text-12">HOME</a>
          <div class="flex items-center space-x-1">
            <p class="font-medium text-12">ROOMS</p>
            <DropdownIcon />
          </div>
          <p class="font-medium text-12">RESTAURANT</p>
          <p class="font-medium text-12">ABOUT US</p>
          <p class="font-medium text-12">BLOG</p>
          <p class="font-medium text-12">CONTACT</p>
        </div>
      </div>
      <div
        class="col-span-10 nav-items hidden lg:flex justify-end space-x-12 self-center"
      >
        <div>
          <a href="/" class="font-medium text-12">HOME</a>
        </div>
        <div class="flex items-center space-x-1">
          <p class="font-medium text-12">ROOMS</p>
          <DropdownIcon />
        </div>
        <div>
          <p class="font-medium text-12">RESTAURANT</p>
        </div>
        <div>
          <p class="font-medium text-12">ABOUT US</p>
        </div>
        <div>
          <p class="font-medium text-12">BLOG</p>
        </div>
        <div>
          <p class="font-medium text-12">CONTACT</p>
        </div>
      </div>
    </div>
    <div class="hero-text px-4 sm:px-32 xl:w-3/6 py-20 space-y-6 text-left">
      <h1
        class="font-extrabold text-5xl lg:text-42 sm:leading-73 observeFadeIn"
      >
        Rooms
      </h1>
      <div class="flex space-x-4 divide-x-2 observeFadeInSlow">
        <div class="col-span-2">
          <p>Home</p>
        </div>
        <div class="pl-4 col-span-2">
          <p>Rooms</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropdownIcon from "@/assets/icons/Dropdown.vue";
import MenuIcon from "@/assets/icons/MenuIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    DropdownIcon,
    MenuIcon,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toogleMenu(state) {
      this.active = state;
    },
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
  },
};
</script>
<style scoped>
@import "../assets/css/animate.css";
.hero {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("../assets/img/hero_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>