export const animationIntersectionObserver = {
    methods: {
        /**
         * add an intersection observer (io) to an element
         * @param (string) classname - class name of element you want to add io to, can be reused for elements that you want to apply the same io to,  conventional example - observeFadein, observeFadeInDelay1s
         * @param (string) animation - animation class name you want to add, conventional example - animated fadeIn, aminated fadeInUp delay-1s
         * @param (object) config (optional) - this is the config object to if you want to, conventional eg - {root: null, threshold: 0.2}
         **/
        observeInteraction(className, animation, config) {
            let classes = document.querySelectorAll(`.${className}`);
            classes.forEach(classItem => {
                classItem.className += ' opacity-0 ';
            });
            let animationObserver = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.className += ` ${animation}`;
                    } else if (!entry.isIntersecting) {
                        entry.target.className = entry.target.className.replace(
                            `${animation}`,
                            '',
                        );
                    }
                });
            }, config);
            classes.forEach(classItem => animationObserver.observe(classItem));
        },
    },
};
