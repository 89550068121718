<template>
  <div class="px-4 lg:px-32 bg-black space-y-14 pt-20 pb-10">
    <div class="grid grid-cols-12 gap-y-4">
      <div
        class="col-span-12 sm:col-span-4 space-y-4 sm:w-5/6 lg:w-4/6 observeFadeIn"
      >
        <p class="text-white text-24 font-bold">Zetta Hotels</p>
        <p class="text-gray-dark text-xs xl:text-14 font-medium">
          Our hotel is an image of quality and profesionalism combined with hard
          work and precision. You can expect only the best services from our
          staff.
        </p>
        <div class="social-icons flex space-x-3">
          <div>
            <img class="max-w-40" src="@/assets/img/facebook.png" />
          </div>
          <div>
            <img class="max-w-40" src="@/assets/img/twitter.png" />
          </div>
          <div>
            <img class="max-w-40" src="@/assets/img/google_plus.png" />
          </div>
        </div>
      </div>
      <div class="col-span-6 sm:col-span-2 observeFadeInSlow">
        <p class="text-18 font-semibold text-white">Useful links</p>
        <div class="pt-4 space-y-3">
          <p class="text-blue-light fontmedium text-14">Blog</p>
          <p class="text-blue-light fontmedium text-14">Rooms</p>
          <p class="text-blue-light fontmedium text-14">Gift Card</p>
          <p class="text-blue-light fontmedium text-14">Testimonials</p>
        </div>
      </div>
      <div class="col-span-6 sm:col-span-2 observeFadeInSlower">
        <p class="text-18 font-semibold text-white">Privacy</p>
        <div class="pt-4 space-y-3">
          <p class="text-blue-light fontmedium text-14">Career</p>
          <p class="text-blue-light fontmedium text-14">About Us</p>
          <p class="text-blue-light fontmedium text-14">Contact Us</p>
          <p class="text-blue-light fontmedium text-14">Services</p>
        </div>
      </div>
      <div class="col-span-12 sm:col-span-3 space-y-4 observeFadeInSlow3">
        <p class="text-18 font-semibold text-white">Contact Info</p>
        <div class="contact space-y-4">
          <div class="flex items-center space-x-3">
            <MailIcon />
            <span class="font-medium text-14 text-white"
              >example@yahoo.com</span
            >
          </div>
          <div class="flex items-center space-x-3">
            <PhoneIcon />
            <span class="font-medium text-14 text-white">+0 320 422 4254</span>
          </div>
          <div class="flex items-center space-x-3">
            <MapIcon />
            <span class="font-medium text-14 text-white"
              >Main Str Chicago Ilinois</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="copyright grid grid-cols-12 observeFadeInSlow3">
      <div class="col-span-12 sm:col-span-8">
        <p class="font-medium text-gray-dark text-14">
          Copyright ©2020 All rights reserved | This template is made with love
          by <span class="text-blue-light">Marian</span>
        </p>
      </div>
      <div class="col-span-4">
        <p class="text-blue-light text-14 font-medium">www.portfolioname.com</p>
      </div>
    </div>
  </div>
</template>
<script>
import MailIcon from "@/assets/icons/MailIcon.vue";
import PhoneIcon from "@/assets/icons/PhoneIcon.vue";
import MapIcon from "@/assets/icons/MapIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    MailIcon,
    PhoneIcon,
    MapIcon,
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInDelay", "animated fadeIn delay-1s");
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
    this.observeInteraction("observeFadeInSlower", "animated fadeIn slower");
    this.observeInteraction("observeFadeInSlow3", "animated fadeIn slow3");
  },
};
</script>