<template>
  <div class="space-y-4">
    <Avatars :active="active" />
    <div class="max-w-85">
      <Slider @changetestimony="changetestimony($event)" :active="active" />
    </div>
  </div>
</template>
<script>
import Avatars from "@/components/Avatars.vue";
import Slider from "@/components/Slider.vue";

export default {
  data() {
    return {
      active: 1,
    };
  },
  components: {
    Avatars,
    Slider,
  },
  methods: {
    changetestimony(testimony) {
      this.active = testimony;
    },
  },
};
</script>