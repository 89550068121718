<template>
  <div class="hero text-white">
    <div
      class="navbar items-center grid grid-cols-12 px-4 sm:px-32 py-6 observeFadeIn"
    >
      <div class="col-span-4 sm:col-span-2 logo">
        <p class="font-semibold text-14">Zetta Hotels</p>
      </div>
      <!-- navbar for mobile -->
      <div
        class="col-span-8 sm:col-span-10 nav-items grid lg:hidden justify-end self-center relative"
      >
        <div>
          <MenuIcon
            :active="active"
            @toogleMenu="toogleMenu($event)"
          ></MenuIcon>
        </div>
        <div
          v-show="active"
          class="absolute bg-black grid justify-end p-4"
          style="top: 25px; right: 0px"
        >
          <p class="font-medium text-12">HOME</p>
          <a href="/rooms" class="flex items-center space-x-1">
            <p class="font-medium text-12">ROOMS</p>
            <DropdownIcon />
          </a>
          <p class="font-medium text-12">RESTAURANT</p>
          <p class="font-medium text-12">ABOUT US</p>
          <p class="font-medium text-12">BLOG</p>
          <p class="font-medium text-12">CONTACT</p>
        </div>
      </div>
      <!-- navbar for desktop -->
      <div
        class="col-span-10 nav-items hidden lg:flex justify-end space-x-12 self-center"
      >
        <div>
          <p class="font-medium text-12">HOME</p>
        </div>
        <a href="/rooms" class="flex items-center space-x-1">
          <p class="font-medium text-12">ROOMS</p>
          <DropdownIcon />
        </a>
        <div>
          <p class="font-medium text-12">RESTAURANT</p>
        </div>
        <div>
          <p class="font-medium text-12">ABOUT US</p>
        </div>
        <div>
          <p class="font-medium text-12">BLOG</p>
        </div>
        <div>
          <p class="font-medium text-12">CONTACT</p>
        </div>
      </div>
    </div>
    <div
      class="hero-text px-4 sm:px-32 xl:w-3/6 py-60 space-y-6 text-center xl:text-left"
    >
      <h1
        class="font-extrabold text-5xl lg:text-62 sm:leading-73 observeFadeIn"
      >
        The best hotel you will ever need
      </h1>
      <p class="font-normal text-14 xl:w-3/5 observeFadeInSlow">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
    </div>
  </div>
</template>
<script>
import DropdownIcon from "@/assets/icons/Dropdown.vue";
import MenuIcon from "@/assets/icons/MenuIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    DropdownIcon,
    MenuIcon,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    toogleMenu(state) {
      this.active = state;
    },
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
  },
};
</script>
<style scoped>
@import "../assets/css/animate.css";
.hero {
  background-image: url("../assets/img/hero_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>