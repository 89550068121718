<template>
  <div>
    <Avatar v-show="active === 1" />
    <Avatar name="Foster Mike" position="Marketer" v-show="active === 2" />
    <Avatar
      name="Larry Holmes"
      position="Customer Suppoer"
      v-show="active === 3"
    />
    <Avatar name="Philips Rice" position="Designer" v-show="active === 4" />
  </div>
</template>
<script>
import Avatar from "@/components/Avatar.vue";
export default {
  components: {
    Avatar,
  },
  props: {
    active: {
      default: 1,
    },
  },
};
</script>