<template>
  <div class="booking px-4 sm:px-32 absolute w-full" style="top: -130px">
    <div class="arrows flex justify-end self-center">
      <div class="p-4 bg-white">
        <LeftArrowIcon />
      </div>
      <div class="p-4 bg-blue-light">
        <RightArrowIcon />
      </div>
    </div>
    <div
      class="booking-date bg-white shadow-booking grid grid-cols-12 px-6 sm:px-20 py-10 gap-x-4"
    >
      <div class="col-span-12 lg:col-span-11 grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-4 space-y-1">
          <p class="text-blue-light font-medium text-13">Check in Date</p>
          <input
            class="focus:outline-none bg-gray-light px-4 py-4 w-full"
            placeholder="check in date"
          />
        </div>
        <div class="col-span-12 lg:col-span-4 space-y-1">
          <p class="text-blue-light font-medium text-13">Check Out Date</p>
          <input
            class="focus:outline-none bg-gray-light px-4 py-4 w-full"
            placeholder="check out date"
          />
        </div>
        <div class="col-span-12 lg:col-span-4 space-y-1">
          <p class="text-blue-light font-medium text-13">Rooms</p>
          <input
            class="focus:outline-none bg-gray-light px-4 py-4 w-full"
            placeholder="rooms"
          />
        </div>
      </div>
      <div class="mx-auto">
        <p class="invisible">Send</p>
        <div class="p-5 bg-blue-light">
          <SendIcon />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LeftArrowIcon from "@/assets/icons/LeftArrowIcon.vue";
import RightArrowIcon from "@/assets/icons/RightArrowIcon.vue";
import SendIcon from "@/assets/icons/SendIcon.vue";
export default {
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    SendIcon,
  },
};
</script>