<template>
  <div class="room-booking px-4 sm:px-32 w-full bg-gray-bg observeFadeIn">
    <div class="booking-date grid grid-cols-12 py-10 gap-x-4">
      <div class="col-span-12 lg:col-span-12 grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-3 space-y-1">
          <p class="text-blue-light font-medium text-13">Check in Date</p>
          <input
            class="focus:outline-none bg-gray-light px-4 py-4 w-full"
            placeholder="check in date"
          />
        </div>
        <div class="col-span-12 lg:col-span-3 space-y-1">
          <p class="text-blue-light font-medium text-13">Check Out Date</p>
          <input
            class="focus:outline-none bg-gray-light px-4 py-4 w-full"
            placeholder="check out date"
          />
        </div>
        <div class="col-span-12 lg:col-span-3 space-y-1">
          <p class="text-blue-light font-medium text-13">Rooms</p>
          <input
            class="focus:outline-none bg-gray-light px-4 py-4 w-full"
            placeholder="rooms"
          />
        </div>
        <div class="col-span-12 lg:col-span-3 space-y-1">
          <div class="mx-auto">
            <p class="invisible">Send</p>
            <div
              class="p-5 bg-blue-light flex items-center space-x-3 justify-center"
            >
              <p class="text-white font-semibold text-13">Submit</p>
              <SendIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SendIcon from "@/assets/icons/SendIcon.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    SendIcon,
  },
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
  },
};
</script>