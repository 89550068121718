<template>
  <div class="space-y-4">
    <div class="flex avatar space-x-6 items-center">
      <div>
        <img class="max-w-70" src="@/assets/img/avatar.png" />
      </div>
      <div class="space-y-2">
        <p class="font-semibold text-18">{{ name }}</p>
        <p class="font-semibold text-12">{{ position }}</p>
      </div>
    </div>
    <p class="text-14 font-medium xl:w-4/6">
      {{ description }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      default: "John Doe",
    },
    position: {
      default: "IT Engineer",
    },
    description: {
      default:
        "I'm very glad I had the opportunity to visit this hotel. The stuff is very friendly and I will definetly visit the hotel again next year.",
    },
  },
};
</script>