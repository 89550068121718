<template>
  <div class="px-4 sm:px-32 pt-24 space-y-12">
    <div class="grid grid-cols-12">
      <div class="col-span-6">
        <p class="text-14 text-blue-light font-bold observeFadeIn">BLOG</p>
        <h2
          class="lg:text-xl xl:text-42 font-extrabold xl:leading-56 observeFadeInSlow"
        >
          Read our newest blog post right away
        </h2>
      </div>
    </div>
    <div class="posts grid grid-cols-12 gap-4 observeFadeInDelay">
      <div class="post col-span-12 sm:col-span-6 lg:col-span-4 bg-gray-bg">
        <div>
          <img src="@/assets/img/rock.png" />
        </div>
        <div class="text-center py-6 w-5/6 sm:w-4/6 mx-auto">
          <p class="text-blue-light text-14 font-bold">Food and life</p>
          <p class="text-18 font-semibold">Succed in hotel business</p>
          <p class="font-medium text-14 text-gray-dark">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
      </div>
      <div class="post col-span-12 sm:col-span-6 lg:col-span-4 bg-gray-bg">
        <div>
          <img src="@/assets/img/cooking.png" />
        </div>
        <div class="text-center py-6 w-5/6 sm:w-4/6 mx-auto">
          <p class="text-blue-light text-14 font-bold">COOKING</p>
          <p class="text-18 font-semibold">Cooking delicious food</p>
          <p class="font-medium text-14 text-gray-dark">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
      </div>
      <div class="post col-span-12 sm:col-span-6 lg:col-span-4 bg-gray-bg">
        <div>
          <img src="@/assets/img/fruits.png" />
        </div>
        <div class="text-center py-6 w-5/6 sm:w-4/6 mx-auto">
          <p class="text-blue-light text-14 font-bold">FRUITS</p>
          <p class="text-18 font-semibold">Getting fresh fruits</p>
          <p class="font-medium text-14 text-gray-dark">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
      </div>
      <div class="post col-span-12 sm:col-span-6 lg:col-span-4 bg-gray-bg">
        <div>
          <img src="@/assets/img/fruits.png" />
        </div>
        <div class="text-center py-6 w-5/6 sm:w-4/6 mx-auto">
          <p class="text-blue-light text-14 font-bold">FRUITS</p>
          <p class="text-18 font-semibold">Getting fresh fruits</p>
          <p class="font-medium text-14 text-gray-dark">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
      </div>
      <div class="post col-span-12 sm:col-span-6 lg:col-span-4 bg-gray-bg">
        <div>
          <img src="@/assets/img/cooking.png" />
        </div>
        <div class="text-center py-6 w-5/6 sm:w-4/6 mx-auto">
          <p class="text-blue-light text-14 font-bold">COOKING</p>
          <p class="text-18 font-semibold">Cooking delicious food</p>
          <p class="font-medium text-14 text-gray-dark">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
      </div>
      <div class="post col-span-12 sm:col-span-6 lg:col-span-4 bg-gray-bg">
        <div>
          <img src="@/assets/img/rock.png" />
        </div>
        <div class="text-center py-6 w-5/6 sm:w-4/6 mx-auto">
          <p class="text-blue-light text-14 font-bold">Food and life</p>
          <p class="text-18 font-semibold">Succed in hotel business</p>
          <p class="font-medium text-14 text-gray-dark">
            Fresh food directly from our restaurant ready coocked for you and
            you familly
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  mounted() {
    this.observeInteraction("observeFadeIn", "animated fadeIn", {
      threshold: 0.2,
    });
    this.observeInteraction("observeFadeInDelay", "animated fadeIn delay-1s");
    this.observeInteraction("observeFadeInSlow", "animated fadeIn slow");
  },
};
</script>