<template>
  <div>
    <Hero />
    <div class="relative observeFadeInUp">
      <Booking />
    </div>
    <About />
    <About2 />
    <Testimonial />
    <Restaurant />
    <Blog />
    <Photos />
    <Footer />
  </div>
</template>
<script>
import Hero from "@/layouts/Hero.vue";
import Booking from "@/layouts/Booking.vue";
import About from "@/layouts/About.vue";
import About2 from "@/layouts/About_2.vue";
import Testimonial from "@/layouts/Testimonial.vue";
import Restaurant from "@/layouts/Restaurant.vue";
import Blog from "@/layouts/Blog.vue";
import Photos from "@/layouts/Photos.vue";
import Footer from "@/layouts/Footer.vue";
import { animationIntersectionObserver } from "@/mixins/animationIntersectionObserver.js";

export default {
  mixins: [animationIntersectionObserver],
  components: {
    Hero,
    Booking,
    About,
    About2,
    Testimonial,
    Restaurant,
    Blog,
    Photos,
    Footer,
  },
  mounted() {
    this.observeInteraction("observeFadeInUp", "animated fadeInUp");
  },
};
</script>