import Vue from 'vue'
import Homepage from "@/views/Homepage.vue";
import Rooms from "@/views/Rooms.vue";
import "tailwindcss/tailwind.css"

Vue.config.productionTip = false

const routes = {
  '/': Homepage,
  '/rooms': Rooms
}

new Vue({
  data: {
    currentRoute: window.location.pathname
  },
  computed: {
    ViewComponent() {
      return routes[this.currentRoute]
    }
  },
  render(h) { return h(this.ViewComponent) },
}).$mount('#app')
